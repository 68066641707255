@import 'src/styles/shared';

.my-accounts-container {
  @extend .flex-row;
  @extend .content-container;
  align-items: flex-start;
  width: 100%;

  .my-accounts-stats-container {
    @extend .content-container;
    @extend .flex-row;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 20px;
    width: 38%;

    .my-accounts-stats-header {
      display: flex;
      width: 100%;
      margin-left: 6px;
    }

    .stats-card {
      margin: 14px 14px 0 6px; // top right bottom left
    }
  }

  .my-accounts-lists-container {
    @extend .content-container;
    @extend .flex-column;
    flex-wrap: wrap;
    padding: 15px 20px 0 20px; // top right bottom left
    width: 62%;
  }

  .accounts-table {
    @extend .content-container;
    @extend .flex-column;
    word-wrap: break-word;

    .accounts-pagination {
      align-self: flex-end;
      z-index: 0 !important;
    }
  }
}
