@import '../../styles/shared.scss';

.create-account-form{
    @extend .content-container
}

.show-feedback {
    display: block;
}

.btn:disabled {
    pointer-events: none;
}
.contact-save-button{
    display: flex;
    margin-bottom: 1rem;
    margin-left: auto;
}