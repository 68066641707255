@import 'src/styles/shared';

.feature-tooltip > .tooltip-inner {
    background-color: $dark-background-color;
    color: $dark-bg-font-color;
}

.feature-tooltip > .bs-tooltip-auto[x-placement^="top"] span.arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: $dark-background-color;
}
