.contact-input-container {
  display: flex;

  .contact-input {
    margin-right: 5px;
  }
}

.contact-input-typeahead {
  width: 700px;
}
