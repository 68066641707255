@import 'src/styles/shared';

.list-card {
  display: flex;
  @extend .flex-column;

  .list-card-table {
    @extend .content-container;
    @extend .flex-column;
    width: 100%;
    //padding: 0 20px 0 20px;
    word-wrap: break-word;

    .list-card-header {
      font-size: 22px;
      font-weight: bold;
      justify-content: space-between;
      align-items: flex-end;
      @extend .flex-row;

      .list-card-title {
        margin-bottom: 12px;
      }
    }
  }
}
