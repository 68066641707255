@import 'src/styles/shared';

.stats-card {
  display: flex;
  align-items: center;
  text-align: center;
  width: 18rem;

  .stats-card-value {
    font-weight: normal;
    font-size: 48px;
  }

  .stats-card-title {
    font-weight: normal;
    color: $dark-background-color;
  }
}
