@import '../../styles/shared.scss';

.accounts-header {
  font-size: 35px;
  font-weight: 500;
  margin-top: 20px;
}

.accounts-container {
  align-items: left;
  @extend .flex-column;
  @extend .content-container;

  .form-group {
    margin-bottom: 0;
  }

  h1 {
    font-size: 48px;
  }
}

.accounts-search-container {
  @extend .flex-column;
  justify-content: space-between;
}

.free-search {
  margin-top: 20px;
}
.react-bootstrap-table-page-btns-ul{
  float:right;
  margin-bottom:0;
  @media only screen and (max-width: 600px) and (min-width: 400px)
  {
    margin-top: 5px;
  }
}
.toggle-bar {
  transition: width 1s linear;
  text-align: center;
  background-color: #ececec;
  border-radius: 5px;
  padding: 7px 0;
  margin: 0 0 5px 0;
  @media only screen and (max-width: 600px) and (min-width: 400px)
  {
    margin: 5px 0 5px 0;
  }
}

.visible-true {
  display: block;
  height: auto;
}

.visible-false {
  display: none;
}
.tsf-export{
  padding: 0;
  margin-right: 5px;
}
.column-toggle , .tsf-export {
  margin-left: auto;
  margin-bottom: 5px;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: #dbdbdb;
  svg{
    vertical-align: initial;
    width: 100%;
    margin: auto;
  }
}

.searchico {
  position: absolute;
  top: 12px;
  right: 15px;
}

.accounts-table {
  @extend .content-container;
  @extend .flex-column;
  word-wrap: break-word;

  .accounts-pagination {
    align-self: flex-end;
    z-index: 0 !important;
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: #17191c;
    border-color: #a9cbff;
    border-radius: 10px;
  }

  .page-link {
    color: #a1a0ab;
  }

  .search-label {
    width: 100%;

    .form-control {
      border: 0;
      border-bottom: 2px solid black;
      border-radius: 0;
    }
  }

  table {
    border: none;
    font-size: 13px;
    border-collapse: separate;
    border-spacing: 0 0.3rem;

    thead {
      border-spacing: 0 0.6rem;
      text-align: center;
      background-color: #0a112e;
      color: #fff;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.25) 1px 4px 6px;

      th {
        font-size: 15px;
        width: auto;
        vertical-align: top;

        label {
          display: block;
        }

        select {
          font-size: 13px;
        }
      }

      tr {
        th:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        th:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    tbody {
      tr {
        font-size: 15px;
        text-align: left;

        td:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        td:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    th,
    td {
      border: none;
    }
  }

}

.warning-table-cell {
  background-color: yellow;
}