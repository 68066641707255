@import '../../styles/shared.scss';

.account-details-container {

  @extend .flex-column;

  svg {
    margin-left: 5px;
  }

  .account-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .account-actions-container {
      @extend .flex-row;
      white-space: nowrap;

      .account-refresh-button-content {
        @extend .flex-row;
        align-items: center;
        white-space: nowrap;
      }

      button {
        margin-left: 15px;
      }
    }
  }

  .account-details {

      .account-features-container {
        @extend .flex-column;
      }
    
      .account-contact-details {
        @extend .flex-column;
      }

    h2 {
      @extend .flex-row;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
}

table {
  width: 80%;
}

table th {
  width: 30%;
  font-weight: bold;
}

table td {
  align-items: center;
}

table input {
  width: 100%;
}

.info {
  display: block;
  font-style: italic;
}
