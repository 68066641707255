@import '../../styles/shared';

#header-wrapper {
  z-index: 10000;
  background-color: $dark-background-color;
  color: $dark-bg-font-color;

  #header {
    @extend .horizontal-screen-scaling-content;
    
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;

    .row {
      width: 100%;

      .login {
        padding-top: 5px;
        text-align: right;
      }
    }

    .navbar-brand {
      @extend .btn;
      display: flex;
      font-weight: bold;
      font-size: 25px;

      #header-logo {
        width: 35px;
        height: 35px;
      }

      #header-label {
        margin-left: 12px;
      }
    }

    #basic-navbar-nav {
      margin-top: 3px;
    }

    .navbar {
      padding: 0;
    }

    .navbar-dark .navbar-nav .nav-link {
      color: $dark-bg-font-color;
      font-weight: bold;
      font-size: 20px;
      @extend .dark-background-link;
    }

    .dropdown-item {
      @extend .btn;
    }
    
    .waffle-iron-link {
      @extend .dark-background-link;
    }
  }
}
