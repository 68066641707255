$black: #111111;
$white: #ffffff;
$dark-background-color: #222222;
$dark-bg-font-color: #F7F7F7;
$off-white: #f2f2f2;
$light-grey: gainsboro;

.no-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.btn-outline-warning {
  font-weight: bold !important;
}

.content-container {
  width: 90vw;
  margin: 0 auto;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

.line-break {
  align-self: center;
  border-bottom: $black solid 1px;
  width: 96%;
}

.un-selectable {
  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.btn {
  @extend .un-selectable;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.dark-background-link {
  color: $dark-bg-font-color;
  &:hover {
    color: $light-grey;
    text-decoration: none;
  }
}

.horizontal-screen-scaling-content {
  width: 100%;

  @media (min-width: 1201px) {
    width: 90%;
  }

  @media (min-width: 1500px) {
    width: 80%;
  }

  @media (min-width: 1680px) {
    width: 75%;
  }

  @media (min-width: 2000px) {
    width: 65%;
  }

  @media (min-width: 2200px) {
    width: 55%;
  }
}
